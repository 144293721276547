@import '../../../styles/variables';

.slider {
  align-self: center;
  margin: 70px auto;
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  background-color: $color-background-deepgrey;
  justify-content: center;
  min-height: 500px;
  box-sizing: border-box;

  @media (max-width: 520px) {
    gap: 12px;
  }

  &__container {
    align-self: center;
    justify-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 920px;
    width: 100%;
    gap: 40px;
    padding: 50px 0;
    align-items: flex-start;

    @media (max-width: 1010px) {
      flex-direction: column;
      gap: 30px;
      padding: 80px 0;
      align-self: flex-start;
    }
  }

  &__img {
    width: 80%;
    max-width: 400px;
    min-height: 180px;
    flex-grow: 1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    align-self: center;

    @media (max-width: 520px) {
      width: 94vw;
    }
  }

  &__btn {
    width: 40px;
    height: 40px;
    margin: 10px;
    z-index: 2;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
      @include hovered;
    }

    @media (max-width: 520px) {
      position: absolute;
      top: auto;
      bottom: 40px;
      width: 30px;
      height: 30px;
      margin: 6px;
    }

    &_left {
      @media (max-width: 520px) {
        left: 20px;
      }
    }

    &_right {
      @media (max-width: 520px) {
        right: 20px;
      }
    }
  }

  &__text {
    line-height: 30px;
    font-size: 18px;
    font-family: sans-serif;
    color: $color-font-grey;
    letter-spacing: 2px;
    font-weight: normal;
    align-self: flex-start;
    margin-bottom: 0;

    @media (max-width: 520px) {
      line-height: 26px;
      font-size: 14px;
    }
  }

  &__title {
    line-height: 30px;
    font-size: 24px;
    font-family: sans-serif;
    color: $color-font-grey;
    letter-spacing: 2px;
    align-self: flex-start;
    margin: 0;
    font-weight: 600;
  
    &::last-word {
      color: $color-span-deeppink;
    }
  
    @media (max-width: 520px) {
      line-height: 26px;
      font-size: 20px;
    }
  }

  &__textblock {
    display: flex;
    flex-direction: column;
  
    @media (max-width: 1010px) {
      min-height: 186px;
    }
    
    @media (max-width: 880px) {
      min-height: 216px;
    }
    
    @media (max-width: 800px) {
      min-height: 256px;
    }
    
    @media (max-width: 630px) {
      min-height: 276px;
    }
    
    @media (max-width: 520px) {
      padding: 20px 30px;
      min-height: 216px;
    }
    
    @media (max-width: 400px) {
      padding: 20px 12px;
      min-height: 256px;
    }
    
    @media (max-width: 330px) {
      min-height: 302px;
    }
  }
}

.span {
  text-decoration: line-through;

  &_color {
    color: $color-span-pink;
  }

  &_color2 {
    color: $color-span-deeppink;
    font-weight: 600;
  }

  &_color3 {
    color: $color-span-blue;
    font-weight: 600;
  }
}
