@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preloader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;

  &_isActive {
    display: flex;
  }

  &__container {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 0 auto;

    @media (max-width: 550px) {
      width: 210px;
      height: 210px;
    }
  }

  &__round {
    width: 300px;
    height: 300px;
    animation:
      any 2s,
      rotation 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-filter: drop-shadow(3px 5px 5px #000);
    filter: drop-shadow(3px 5px 5px #000);

    @media (max-width: 550px) {
      width: 210px;
      height: 210px;
    }
  }
}
