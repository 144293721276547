@import '../../../styles/variables';

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.battle {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 70px 70px 70px 70px;
  margin-right: auto;
  margin-left: auto;
  background-color: $color-background-grey;

  @media (max-width: 770px) {
    padding: 90px 50px 90px 50px;
  }
  
  
  @media (max-width: 450px) {
    padding: 70px 20px 70px 20px;
  }

  &__storyText {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin: 40px auto 40px auto;
    min-height: 136px;
    width: 710px;
    border-radius: 0 10px 0 10px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 12px;
    background: $color-font-white;
    box-sizing: border-box;

    @media (max-width: 850px) {
      min-width: 240px;
      width: calc(90%);
      height: -moz-fit-content;
      height: fit-content;
      font-size: 16px;
    }
  }

  &__buttonBlocked {
    display: none;
  }

  &__resImg {
    opacity: 0;
    animation: ani 2s forwards;
  }

  &__emptyBlock {
    height: 42px;

    @media (max-width: 737px) {
      height: 62px;
    }

    @media (max-width: 500px) {
      height: 92px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;

  & img {
    width: 120px;
    height: 120px;
    margin-top: 20px;
  }
}
