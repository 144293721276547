@import '../../../styles/variables';

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cardsHolder {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 70px 100px 70px;
  max-width: 1140px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 770px) {
    padding: 90px 50px 90px 50px;
  }

  @media (max-width: 650px) {
    padding: 70px 14px 70px 14px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 80%;
    padding-top: 30px;
  }

  &__input {
    display: block;
    height: 40px;
    border-radius: 20px;
    padding-left: 20px;
    background-color: $color-input-lightgrey;
    border: 1px solid $color-background-middlegrey;

    &_active {
      background-color: $color-input-correct;

      &:focus {
        background-color: $color-input-correct;
      }

      &:active {
        font-weight: bold;
        background-color: $color-input-correct;
      }
    }

    @media (max-width: 570px) {
      padding-left: 15px;
      min-width: 250px;
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    justify-items: end;
    align-items: center;
    background-color: $color-background-card;
    border-radius: 20px;
    border: 1px solid $color-border-grey;
    margin: 20px 0;
    gap: 10px;
    width: 350px;
    padding: 5px 15px;
    justify-content: space-between;

    @media (max-width: 570px) {
      width: 250px;
    }

    & p {
      margin: 0;
      width: 100px;
    }
  }

  &__button1 {
    margin-top: 20px;
  }

  &__buttonBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (max-width: 570px) {
      flex-direction: column;
      // for old iphon browsers
      gap: 0;
    }
  }

  &__title {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    margin: 0;
    padding-bottom: 23px;

    @media (max-width: 650px) {
      padding-bottom: 28px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__pensil {
    width: 25px;
    height: 25px;

    &:hover {
      @include hovered;
    }
  }
}

.btn {
  text-decoration: none;
  border: none;
  position: relative;
  overflow: hidden;
  width: 200px;
  font-weight: 600;
  border-radius: 20px;
  text-align: center;
  min-height: 40px;
  height: fit-content;
  margin: 10px 0;
  

  &__battle {
    background-color: $color-background-white;
  }

  &__words {
    background-color: $color-background-middlegrey;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $color-button-hovered;
    transition: all 0.35s;
    opacity: 0.4;
    border-radius: 20px;
  }

  &:hover:after {
    width: 100%;
  }
}
