@import '../../styles/variables';

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-height: calc(100vh - 262px);

  @media (max-width: 600px) {
    min-height: calc(100vh - 248px);
  }

  &__title {
    font-family: "Inter";
    font-weight: 400;
    font-size: 60px;
    line-height: 68px;
    text-align: center;
    margin: 0;
    padding-top: 40px;

    @media (max-width: 770px) {
      padding-top: 20px;
    }
    
    @media (max-width: 550px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 0;
    padding-top: 5px;

    @media (max-width: 550px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__link {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    padding-top: 10px;
    color: $color-font-link;

    &:hover {
      @include hovered;
    }

    @media (max-width: 550px) {
      padding-top: 10px;
    }
  }

  &__imgBlock {
    width: 400px;
    height: 400px;
    display: flex;
    position: relative;
    justify-content: center;
  
    @media (max-width: 500px) {
      width: 98vw;
      min-height: 320px;
    }
    
    @media (max-width: 330px) {
      height: auto;
      min-height: 307px;
    }
  }  

  &__img {
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
  
    @media (max-width: 500px) {
      width: 92%;
      height: 90%;
    }
  }

  &__btn {
    width: 30px;
    height: 60px;
    margin: 10px;
    z-index: 2;
    position: absolute;
    top: 35%;

    &:hover {
      @include hovered;
    }

    &_left {
      left: -60px;

      @media (max-width: 560px) {
        top: auto;
        bottom: -80px;
        left: 30%;
      }
    }

    &_right {
      right: -50px;

      @media (max-width: 560px) {
        top: auto;
        bottom: -80px;
        left: 55%;
      }
    }
  }
}
